import { type FunctionComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SharedFAQAuthor } from './SharedFAQAuthor';

import { type SharedFAQAnswer_answer$data } from './__generated__/SharedFAQAnswer_answer.graphql';
import styles from './SharedFAQAnswer.scss';

type Props = {
    answer: SharedFAQAnswer_answer$data;
};

export const SharedFAQAnswerComponent: FunctionComponent<Props> = ({ answer }) => {
    const { serviceId, text, author, formattedCreatedDate } = answer;

    if (text) {
        return (
            <div key={serviceId} className={styles.answer}>
                <SharedFAQAuthor author={author} formattedCreatedDate={formattedCreatedDate} />
                <div
                    data-tn="faq-answer-text"
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
            </div>
        );
    }
    return null;
};

export const SharedFAQAnswer = createFragmentContainer(SharedFAQAnswerComponent, {
    answer: graphql`
        fragment SharedFAQAnswer_answer on AnswerType {
            serviceId
            text
            formattedCreatedDate
            author {
                ...SharedFAQAuthor_author
            }
        }
    `,
});
