import { type FunctionComponent, useState } from 'react';
import { Button } from 'dibs-elements/exports/Button';
import classname from 'classnames';

import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SharedFAQQuestion } from './SharedFAQQuestion';
import { frequentlyAskedQuestions as frequentlyAskedQuestionsTitle } from '../../buy/buyMessages';
import { viewMore, viewLess } from '../../finding/sbMessages';
import { INITIAL_VISIBLE_QA_ITEMS, EXPANDED_VISIBLE_QA_ITEMS } from '../../constants/sbConstants';

import { type SharedFAQ_itemSearch$data } from './__generated__/SharedFAQ_itemSearch.graphql';

import styles from './SharedFAQ.scss';

type Props = {
    itemSearch: SharedFAQ_itemSearch$data;
    header?: string;
};

const SharedFAQComponent: FunctionComponent<Props> = ({ itemSearch, header }) => {
    const [expanded, setExpanded] = useState(false);
    const visibleQuestionCount = expanded ? EXPANDED_VISIBLE_QA_ITEMS : INITIAL_VISIBLE_QA_ITEMS;
    const questions = itemSearch.questionsAnswers || [];

    if (questions.length) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.header} data-tn="faq-header">
                    {frequentlyAskedQuestionsTitle(header || itemSearch.faqHeader)}
                </div>

                <ul>
                    {questions.map((question, index) => (
                        <SharedFAQQuestion
                            key={question?.id}
                            className={classname({
                                [styles.hideItem]: index >= visibleQuestionCount,
                            })}
                            question={question}
                        />
                    ))}
                </ul>

                {questions.length > INITIAL_VISIBLE_QA_ITEMS && (
                    <div className={styles.viewMoreButtonWrapper}>
                        <Button
                            type="secondary"
                            size="medium"
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            className={styles.viewMoreButton}
                        >
                            {expanded ? viewLess : viewMore}
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    return null;
};

export const SharedFAQ = createFragmentContainer(SharedFAQComponent, {
    itemSearch: graphql`
        fragment SharedFAQ_itemSearch on ItemSearchQueryConnection {
            faqHeader
            questionsAnswers {
                id
                ...SharedFAQQuestion_question
            }
        }
    `,
});
