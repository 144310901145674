/**
 * @generated SignedSource<<cd47f672ea9fc517add19fa85ec1c688>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedBottomModuleContent_bottomModule$data = ReadonlyArray<{
  readonly description: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "SharedBottomModuleContent_bottomModule";
}>;
export type SharedBottomModuleContent_bottomModule$key = ReadonlyArray<{
  readonly " $data"?: SharedBottomModuleContent_bottomModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedBottomModuleContent_bottomModule">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SharedBottomModuleContent_bottomModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "BottomModule",
  "abstractKey": null
};

(node as any).hash = "5c394bfcb2e03f15abfc2623193c3bac";

export default node;
