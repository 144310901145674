import { type FunctionComponent, lazy, Suspense } from 'react';
import { graphql, createFragmentContainer } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import { type SharedFAQAuthor_author$data } from './__generated__/SharedFAQAuthor_author.graphql';
import styles from './SharedFAQAuthor.scss';
import { useClientState } from 'dibs-react-hooks/exports/useClientState';

const Logo2020 = lazy(
    () =>
        import(
            /* webpackChunkName: "BuySharedFAQAuthorLogo2020" */ 'dibs-icons/exports/legacy/Logo2020'
        )
);

export type Props = {
    author: SharedFAQAuthor_author$data | null | undefined;
    formattedCreatedDate?: string | null;
};

export const SharedFAQAuthorComponent: FunctionComponent<Props> = ({
    author,
    formattedCreatedDate,
}) => {
    const isClient = useClientState();

    const sellerPreferences = author?.sellerPreferences;
    if (sellerPreferences) {
        const { label: name, scrollLogo: logo, urlLabel } = sellerPreferences;
        if (name) {
            const url = `/dealers/${urlLabel}/`;
            return (
                <div className={styles.author}>
                    {logo && <img className={styles.authorLogo} src={logo} alt={name} />}
                    <div className={styles.authorInfo} data-tn="faq-answer-author">
                        <Link href={url}>{name}</Link>
                        {formattedCreatedDate && <span>{formattedCreatedDate}</span>}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={styles.author}>
            {isClient && (
                <Suspense fallback="">
                    <Logo2020 className={styles.expertLogo} />
                </Suspense>
            )}

            <div className={styles.authorInfo} data-tn="faq-answer-author">
                <FormattedMessage
                    id="SharedFAQ.author.defaultAuthor"
                    defaultMessage="1stDibs Expert"
                />
                {formattedCreatedDate && <span>{formattedCreatedDate}</span>}
            </div>
        </div>
    );
};

export const SharedFAQAuthor = createFragmentContainer(SharedFAQAuthorComponent, {
    author: graphql`
        fragment SharedFAQAuthor_author on Seller {
            sellerPreferences {
                label
                urlLabel
                scrollLogo
            }
        }
    `,
});
